import Left from './Left'
import Right from './Right'

const DestinationsNearYou = ({locale}:{locale:string}) => {
	return (
		<section
			id='destinations-near-you'
			className='px-[13px] lg:py-5 bg-white'>
			<div className='max-w-7xl mx-auto flex items-center justify-center gap-[26px] py-[30px] lg:py-[60px]'>
				<Left />
				<Right locale={locale}/>
			</div>
		</section>
	)
}

export default DestinationsNearYou
