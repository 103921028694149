import PlaceCard from "./PlaceCard";
import { nearYou } from "@/destinations";

const Right = ({ locale }: { locale: string }) => {
  return (
    <>
      <div className="hidden lg:grid grid-cols-4 gap-[18px]">
        {nearYou.map((data) => {
          return (
            <PlaceCard
              key={data.id}
              img={data.img}
              location={data.location}
              price={data.price}
              code={data.code}
              locale={locale}
            />
          );
        })}
      </div>
      <div className="grid lg:hidden">
        <h2 className="text-lg font-extrabold text-my-dark-blue">
          The Most Popular Destinations
        </h2>
        <p className="text-sm text-my-light-blue mt-[6px] mb-[22px]">
          Explore the cities our users love the most.
        </p>
        <div className="flex w-full h-full space-x-8 overflow-scroll">
          {nearYou.map((data) => {
            return (
              <div key={data.id} className="min-w-[150px]">
                <PlaceCard
                  img={data.img}
                  location={data.location}
                  price={data.price}
                  code={data.code}
                  locale={locale}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Right;
