import Image from "next/image";

const Left = () => {
  return (
    <div className="lg:flex flex-col items-start justify-center max-w-[450px] hidden">
      <h2 className="text-lg font-extrabold lg:text-3xl text-my-dark-blue">
        The Most Popular Destinations
      </h2>
      <p className="text-sm lg:text-xl text-my-light-blue mt-[25px]">
        Explore the cities our users love the most.
      </p>
      <Image
        src="/destinations-near/car-top.png"
        width={600}
        height={318}
        alt="Image of a car viewed from the top"
        className="w-auto h-auto mt-3"
      />
    </div>
  );
};

export default Left;
